.news {
  &__new {
    .minco-radio {
      .form-check-label {
        height: calc(3.25rem + 2px);
        width: 7rem;
        &:before {
          height: calc(3.25rem + 2px);
        }
        span {
          display: inline-block;
          font-size: $size-15;
          line-height: normal;
          position: relative;
          vertical-align: middle;
        }
      }
    }
    hr {
      border-width: 2px !important;
    }
  }
  &__edit {
    .icon {
      border: 1px solid black;
      border-radius: 100%;
      font-size: 1rem;
      padding: .5rem;
    }
  }
}