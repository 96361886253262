@import "admin";

.news {

  &__item-title {
    font-size: $size-16;
  }

  &__item-desc {
    font-size: $size-15;
    font-family: $font-family-base;
    font-weight: normal;
  }

  &__content {

  }

  &__more {
    background: $minco-white-three;
    margin: 6em -15px 0;
    padding: 15px 15px 40px;
  }

  &__more-header {
    font-size: $size-20;
  }

  &__more-items {
    font-size: $size-15;
    list-style: none;
  }

  &__more-item {
    display: table;
    width: 100%;
    padding: 1em 0;
    border-bottom: 1px solid $minco-warm-grey;
  }

  &__more-desc {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
  }

  &__more-icon {
    display: table-cell;
    vertical-align: middle;

    img {
      display: inline-block;
      width: 28px;
      height: auto;
    }
  }

  &__more-link {
    display: block;
    font-weight: inherit;
    font-family: inherit;

    &:hover {
      font-weight: bold;
    }
  }

}
