.minco-header {
  background-color: $minco-white;
  box-shadow: 0 3px 4px 0 rgba($minco-black, .09);
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 50;
  &__logo {
    width: 11rem;
    @include media-breakpoint-between(md, lg) {
      width: 9rem;
    }
    @include media-breakpoint-down(md) {
      width: 7rem;
    }
  }
  &__welcome {
    font-family: 'Poppins regular';
    font-size: $size-15;
    line-height: 1.056rem;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  .list-unstyled {
    li {
      text-align: right;
      @include media-breakpoint-down(md) {
        text-align: left;
      }
      a {
        font-size: $size-15;
        display: inline-block;
        vertical-align: top;

        @include media-breakpoint-down(md) {
          font-size: $size-12;
        }
        span {
          @include media-breakpoint-down(md) {
            display: none;
          }
        }
        .icon {
          &-user {
            font-size: 1.389rem;
            @include media-breakpoint-down(md) {
              font-size: 5.6vw;
              margin-right: .7rem !important;
            }
          }
          &-user-cross {
            font-size: 1.667rem;
            @include media-breakpoint-down(md) {
              font-size: 6.5vw;
            }
          }
          &-unswitch {
            font-size: 2.3rem;
            @include media-breakpoint-down(md) {
              font-size: 7.5vw;
            }
          }
        }
      }
    }
  }
}

.divHotLine
{
  font-size:0.8125rem; font-weight: lighter;padding: 0 1.875rem 0 3.4375rem;text-align: left;position:relative;
  height: 2.9375rem;
  top: 0.375rem;
}

.divHotLine i
{
  position:absolute;
  left:1.1875rem;
  top:0.475rem;
  font-size:1.4375rem;
}
