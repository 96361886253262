.vdp-datepicker {
  input:disabled, input[readonly] {
    background-color: #ffffff;
    opacity: 1;
  }

  &:before {
    @extend [class^="icon-"]:before;

    content: "\62";
    position: absolute;
    top: 0.45em;
    left: 0.45em;
    font-size: 1.2em;
    pointer-events: none;
  }

  input {
    padding-left: calc((0.3em + 1.4em) + 0.375rem);
  }
}
