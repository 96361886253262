.swiper-container {
  height: 28.66vw;
  width: 100%;
  &--thinner {
    height: 12.08vw;
    @include media-breakpoint-down(sm) {
      height: 80.08vw;
    }
  }
  .swiper-slide {
    background-size: cover;
    div{
      padding:7.1875rem 0px 0px 6.25rem;
      color: $minco-white;
      background-image: url("~/build/images/common/icons/ico-rectangle.png");
      background-repeat: no-repeat;
      margin:4.75rem;
      @media (max-width: 1280px) {
        margin: 2.25rem;
      }

      @include media-breakpoint-down(lg) {
        padding:4.1875rem 0px 0px 3.25rem;
        background-image: none;
        margin:2.75rem;
      }
      @include media-breakpoint-down(sm) {
        padding:7.5875rem 0px 0px 7.55rem;
        background-image: none;
        margin:0;
      }

      h1{
        width:24.8125rem;
        font-size:1.875rem;
      }
      p{
        width:19.625rem;
        font-size:1.125rem;
      }
      a{
        width: 10.75rem;
        height: 2.75rem;
        border-radius: 1.375rem;
        background-color: #015962;
        text-align: center;
        padding-top:0.6875rem;
        display: block;
      }
    }
  }
}



@import "~swiper/dist/css/swiper";
