.client {
  &__show {
    .list-unstyled {
      li {
        line-height: 1.5;
        .icon {
          font-size: 1.2rem;
        }
      }
    }
    &__roles {
      font-size: 0;
      margin-top: .75rem;
      > span {
        font-size: $font-size-base;
      }
      li {
        display: inline-block;
        font-size: $font-size-base;
        width: 50%;
      }
    }
  }
}