.responsive-table {
  //width: 100%;

  thead th {
    background-color: $minco-dark-blue;
    color: $minco-white;
    font-weight: normal;
  }

  .sorting {
    cursor: pointer;
  }

  &__fixed-text {
    display: block;
    min-height: calc(2.25rem + 2px);
  }

  &__fixed {
    display: block;
    height: calc(2.25rem + 2px);
    line-height: 2.6;
  }

  &__editable {
    &.input-group {
      &--small {
        //width: 3.89vw;
      }
    }
    .editable-field {
      cursor: pointer;
      display: block;
      margin: auto;
      position: relative;
    }
  }

  &__icon {
    font-size: 2em;
  }

  tr {
    border-bottom: 1px solid $minco-white-two;
  }

  th {
    //white-space: nowrap;
  }

  th,
  td {
    padding: .75em .5rem;
    //padding: .75em;
    border-top: none;
  }

  td {
    vertical-align: middle;
    //vertical-align: top;
  }

  @include media-breakpoint-down(md) {
    & {

      table, thead, tbody, th, td, tr {
        display: block;
      }

      thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
      }

      tr {
        border-top: 1px solid #eee;
        border-bottom: none;
        margin-bottom: 1em;
      }

      td {
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 50%;
      }

      td:before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        min-width: 48%;
        padding: 1em 0 1em 1em;
        white-space: nowrap;
        text-align: left;
        background-color: $minco-dark-blue;
        color: white;

        content:attr(data-label);
      }
    }
  }
}
//.sorting {
//  &:after {
//    content: '\4b';
//    cursor: pointer;
//    display: inline-block;
//    font-family: 'minco-extranet';
//    transform: rotate(90deg);
//    transform-origin: center;
//    padding: 0 1em;
//    vertical-align: middle;
//  }
//}
