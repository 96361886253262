.cart-validation {
  &__table {
    margin-bottom: 0;
  }

  &__tip {
    font-size: $size-12;
    color: $minco-warm-grey;
  }

  &__footer {
    padding: .75em;
    background-color: $minco-white-three;
  }
}
