.loader {
  background: rgba(0, 0, 0, 0.7);
  bottom: 0;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  z-index: 2000;

  display: none;

  &--active {
    display: block;
  }
}

.loader__label {
  position: absolute;
  width: auto;
  white-space: nowrap;
  top: 100%;
  padding-top: 10%;
  left: 50%;
  transform: translateX(-50%);
  font-weight: bold;
  color: white;
}

.cssload-loader {
  border-radius: 50%;
  height: 80px;
  left: 0;
  margin: auto;
  perspective: 1000px;
  position: relative;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 80px;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
