.arrowed-link {
  &:before {
    color: $minco-white;
    content: "\53";
    display: inline-block;
    font-family: 'minco-extranet';
    font-size: $size-15;
    vertical-align: middle;
    border-radius: 9.5px;
    background-color: #005962;
    margin-right: 15px;
    width: 30px;
    height: 20px;
    text-align: center;
   
    @include media-breakpoint-down(sm) {
      padding-top: 3px;
    }
  }
}


.arrowed-link-back {
  &:before {
    color: inherit;
    content: "D";
    display: inline-block;
    font-family: 'minco-extranet';
    font-size: $size-15;
    margin-right: .25rem;
    vertical-align: middle;
  }
}
.arrowed-link-rigth
{
  &:after {
    color: inherit;
    content: "S";
    display: inline-block;
    font-family: 'minco-extranet';
    font-size: $size-15;
    margin-left: .25rem;
    vertical-align: middle;
  }
}

.cart-link {
  padding-left: 3em;
  position: relative;
  &:before {
    color: inherit;
    content: "V";
    display: inline-block;
    position: absolute;
    left: 0.8em;
    font-family: 'minco-extranet';
    font-size: $size-20;
    top: 0.2em;
    vertical-align: middle;
  }
}

.fancy-button {
  text-align: left;
  display: inline-block;
  font-size: 1em;

  .icon {
    color: $minco-grass;
    font-size: 3.6em;
    left: 0.35em;
    transform-origin: center;
    transform: rotate(20deg);
    position: relative;
    z-index: 1;

    transition: transform 0.3s ease-in-out;
  }

  span {
    position: relative;
    z-index: 2;
  }

  &:hover {
    .icon {
      transform: rotate(200deg);
    }
  }
}
