.project-navbar {
  $block: &;
  font-size: 0.7rem;

  @include media-breakpoint-up(xl) {
    font-size: 0.833rem;
  }

  &__list {
    background-color: $minco-white;
  }

  &__item {}

  &__link {
    color: black;
    display: inline-block;
    font-size: 1em;

    & > * {
      margin: 0.5em 1em;

      @include media-breakpoint-up(xl) {
        margin: 0.5em 1.5em;
      }
    }

    &.active {
      color: #000;
      
      #{$block}__icon {
        background-color: $minco-yellow;
        color: #fff;

        & + span {
          border-bottom: 3px solid $minco-yellow;
        }
      }
    }
  }

  &__icon {
    font-size: $size-25;
    margin-right: .25em;
    background-color: $minco-dark-blue;
    color: $minco-white;
    padding: .3em;
    border-radius: 9px;
  }

}
