@import "../modules/colors";
@import "../modules/typography";
/*♪┏(°.°)┛┗(°.°)┓♪ Variables ♪┏(°.°)┛┗(°.°)┓┗♪*/
$badge-border-radius: 0;
$font-family-sans-serif: 'Poppins regular', sans-serif;
$hr-border-color: $minco-white-two;
$hr-margin-y: 2.5rem;
$btn-padding-x: 1em;
$link-decoration: none;
$link-hover-decoration: none;
$link-hover-color: none;
$card-border-color: $minco-pinkish-grey;
$card-border-radius: 0;
$nav-tabs-border-radius: 0;
$link-color: $minco-black;

$input-focus-box-shadow: 0 0 5px rgba(248, 192, 131, 1);
$input-focus-border-color: #ffd09d;

$dropdown-link-active-bg: $minco-grass;

$modal-header-border-color: transparent;
$modal-footer-border-color: transparent;

@import "~bootstrap/scss/bootstrap";

/*♪┏(°.°)┛┗(°.°)┓♪ Globals ♪┏(°.°)┛┗(°.°)┓┗♪*/
html {
  font-size: 1rem;
  body {
    line-height: 1.4;
    ol,
    ul {
      margin-bottom: 0;
    }
  }
}

a {
  color: inherit;
}

.modal {
  &-header .close {
    color: transparent;
    height: 4rem;
    position: relative;
    width: 4rem;
    &:hover {
      color: transparent;
    }
    &:before {
      bottom: 0;
      content: "g";
      font-family: 'minco-extranet';
      font-size: 1.8rem;
      left: 0;
      color: $minco-black;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &-title {
    font-family: "Poppins regular", sans-serif !important;
    font-size: $size-25 !important;
    padding: .5rem 1.5rem;
  }
  &-footer {
    padding-right: 4rem !important;
  }
}

.dropdown-toggle::after {
  display: none;
}
