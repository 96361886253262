.account-contributors {
  &__item {
    & + & {
      border-top: 1px solid $minco-black;
    }

    &:nth-child(2n) {
      background: #f0f0f0;
    }
  }


}
