.catalog-request {
  &__list {
    .icon-magnifier {
      font-size: 1.3rem;
    }
    .status{
      font-family: "Poppins regular", sans-serif;
      position: relative;
      .badge {
        position: absolute;
        right: .75rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}