.corporate {
  &__title {
    font-family: "Poppins regular", sans-serif;
    font-size: 2.5rem;

    @include media-breakpoint-up(xl) {
      font-size: 2.75rem;
    }

    margin: 1em auto;
  }

  &__subtitle {
    font-family: "Poppins regular", sans-serif;
    font-size: 1.55rem;

    @include media-breakpoint-up(xl) {
      font-size: 2rem;
    }
    margin: 1em auto;
  }

  &__heading {
    line-height: 1.2em;
    font-size: 1.3rem;

    @include media-breakpoint-up(xl) {
      font-size: 1.3rem;
    }
  }

  &__quote {
    color: #4c4c47;
    font-size: 1.7rem;

    @include media-breakpoint-up(xl) {
      font-size: 2.2rem;
    }
  }
}
