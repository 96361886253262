

.medialist {
  list-style: none;

  &__item {
    border-bottom: 1px solid $minco-black;
    padding: 1em 0;

    display: table;
    width: 100%;
  }

  &__action {
    display: table-cell;
    font-weight: bold;
    text-align: center;
    width: 5rem;
  }


  &__name {
    display: table-cell;
    font-weight: bold;
    text-align: left;
  }

  &__link {
    display: table-cell;
    text-align: right;
  }
}

.h5-connected
{
  font-size: 0.938rem !important;
  color:$minco-black;
  padding-left: 10px;
}

.h5-connected:before
{
  color: #ffffff;
  content: "S";
  display: inline-block;
  font-family: 'minco-extranet';
  font-size: 0.938rem;
  vertical-align: middle;
  border-radius: 9.5px;
  background-color: #005962;
  margin-right: 10px;
  maring-left: -45px;
  width: 30px;
  height: 20px;
  text-align: center;
  padding-top:2px;
}
