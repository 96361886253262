.project-template {
  &__intro {
    font-size: 1.6rem;
    text-align: center;
    font-weight: bold;
    font-family: "Poppins regular", sans-serif;
  }

  &__main-form {
    background-color: $minco-white-three;
  }

  &__form-header {
    font-size: 1.3rem;
    font-family: "Poppins regular", sans-serif;
    font-weight: bold;
  }

  &__form-notice {
    font-family: "Poppins regular", sans-serif;
    font-weight: bold;
  }

  &__form-help {
    color: $minco-warm-grey;
  }

  .input-group-btn {
    margin-bottom: 0;
  }

  label {
    font-family: "Poppins regular", sans-serif;
    font-weight: bold;
  }

  .form-check-label {
    font-family: "Poppins regular Alt", sans-serif;
    font-weight: normal;
    font-size: 0.9rem;
  }
}
