.minco-footer {
  position: relative;
  z-index: 11;
  
  background-color: $minco-black-two;
  font-family: "Proxima Nova";
  font-weight: 600;
  .list-inline {

  }
}

.callToActionClosed
{
  display: none;
  position: absolute;
  right:0.9375rem;
  bottom: 3.5625rem;
  width: 5.375rem;
  height: 5.375rem;
  border-radius: 3.75rem;
  background: $minco-dark-blue;
  text-align: center;
  vertical-align: middle;
  span
  {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
  img
  {
    display: inline-block;
    height: 2.25rem;
  }
}

.callToAction
{
  position: absolute;
  right:0.9375rem;
  bottom: 3.5625rem;
  width: 290px;
  height: 132px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.28);
  background-color: #ffffff;
  color: $minco-dark-blue;
  display:block;
}
.callToActionTitle
{
  background-color: $minco-dark-blue;
  color:$minco-white;
  padding: 0.875rem 0 0.25rem 1.1875rem;
  height:2.875rem;
  width:100%;

}

.callToActionText
{
  padding:1.625rem 0 2rem 1.8125rem;
  font-weight:bold;
}





