.library {
  &__new-edit {
    .minco-checkbox {
      .form-check {
        padding-left: 15px;
        &-label {
          margin-bottom: .5rem;
          width: 100%;
        }
      }
    }
    .media {
      &__download__link {
        display: inline-block;
        vertical-align: middle;
        word-break: break-all;
        width: 85%;

        img {
          height: 34px;
          margin-right: .5rem;
          width: auto;
        }

        & + .media__download__link {
          margin-left: 1em;
        }
      }
    }
  }
}