#minco-sidebar {
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 10;
  pointer-events: none;

  font-size: 1rem;

  .minco-sidebar {
    height: 100%;

    background-color: $minco-white;
    box-shadow: 0 1px 7px 0 rgba($minco-black, 0.5);

    pointer-events: all;

    &__items {
      height: 100%;
    }

    &__item {
      display: block;
      font-family: "Proxima Nova", sans-serif;
      font-size: 1em;
      font-weight: 600;
      line-height: 2.6em;
      position: relative;
      white-space: nowrap;
      background: $minco-white;
      z-index: 1;

      &--extra {
        background: #005962;
      }
    }

    &__link {
      display: inline-block;
      width: 100%;
      color: inherit;
      position: relative;
      font-size: 1em;
    }

    &__icon {
      display: inline-block;
      position: relative;
      vertical-align: top;
      width: 2.5em;
      line-height: 2.6em;
      z-index: 1;

      .icon {
        font-size: 1.25em;
        &-group,
        &-stamp {
          font-size: 1.5em;
        }
      }

    }

    &__label {
      display: inline-block;
      vertical-align: middle;
      line-height: 1em;
      user-select: none;

      @include media-breakpoint-up(md) {
        opacity: 0;
        pointer-events: none;
      }
    }
    &__button-form
    {
      width: 100%;
      appearance: none;
      border: 0;
      background: #fff;
      text-align: left;
      font-style: normal;
      font-family: "Proxima Nova Alt", sans-serif;
      font-size: 0.889em;
      font-weight: 400;
      padding-left: 1em;
      color: #767676;
      cursor: pointer;
      line-height: 2.6em;
      &:hover{
        background: #f0f0f0;
        color: #000000 ;
      }

    }
    .infosContact
    {
      color:#fff;
      margin:10px 0 10px 0;
      text-transform: none;
      line-height: 20px;
      font-weight: 400;
      position: relative;
      vertical-align: top;

      .icon{
        //left: -21px;
        //position: absolute;
      }
    }
    &__sub-items {
      position: relative;

      transition: max-height 0.3s ease-in-out;
      max-height: 0;
      overflow: hidden;

      &--opened {
        //  /!\ UPDATE IF MENU ENTRIES CHANGE
        max-height: 500px;
      }
    }

    &__sub-link {

      display: block;
      font-family: "Proxima Nova Alt", sans-serif;
      font-size: 0.889em;
      font-weight: 400;
      line-height: 2.6em;
      min-height: auto;
      padding: 0 2.8em;
      position: relative;
      color: $minco-warm-grey;

      &:hover,
      &--active {
        background: $minco-white-three !important;
        color: $minco-black !important;
      }
    }

    &__item {

      &:not(.minco-sidebar__item--extra) {
        .minco-sidebar__entry {
          &:hover,
          &--active {
            .minco-sidebar {
              &__link {
                background: #000;
                color: $minco-white;
              }
            }
          }
        }
      }

      &--extra {
        .minco-sidebar__entry {

          .minco-sidebar__label {
            margin-left: 1em;
          }

          &:hover,
          &--active {
            .minco-sidebar {
              &__link {
                background: #005962;
                color: white;
              }
            }
          }
        }
      }
    }

    // CLOSED MENU
    transition: all 0.3s ease-in-out;

    &--closed {
      transform: translateX(-110%);

      .minco-sidebar {

        &__item {
          @include media-breakpoint-up(md) {
            height: 2.6em;

            .minco-sidebar {

              &__label {
                opacity: 0;
                position: absolute;
                left: -9999px;
              }

              &__entry {
                display: inline-block;
                position: relative;
                z-index: 100;

                &:hover {
                  .minco-sidebar{
                    &__label {
                      position: static;
                      left: 0;
                      display: inline-block;
                      opacity: 1;
                      pointer-events: all;
                    }


                    &__sub-items {
                      display: block;
                      max-height: none;
                    }
                  }
                }
              }

              &__sub-items {
                display: none;

                padding-left: 2.5em;
              }

              &__sub-link {
                background: lighten($minco-white-three, 3%);
                color: $minco-warm-grey;
                padding: 0 1em;
              }
            }
          }
        }

      }
    }

    // OPENED MENU

    &--opened {
      transform: translateX(0);

      .minco-sidebar {

        &__entry {

          .minco-sidebar {
            &__link {
              width: 100%;

              &--arrow {
                &:after {
                  content: '\0020';
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 6px 5px 0 5px;
                  border-color: $minco-black-two transparent transparent transparent;
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  right: 1em;
                }
              }
            }
          }

          &:hover,
          &--active {
            .minco-sidebar {
              &__link {
                &--arrow {
                  &:after {
                    border-color: $minco-white transparent transparent transparent;
                  }
                }
              }
            }
          }
        }

        &__item--extra {
          .minco-sidebar__label {
            margin-left: 0em;
          }
        }
      }
    }

    @include media-breakpoint-up(md) {
      transform: translateX(0);

      max-width: 2.5em;

      &--opened {

        //  /!\ UPDATE IF MENU ENTRIES CHANGE
        max-width: 500px;

        .minco-sidebar {

          &__sub-link {
            padding: 0 0.6em 0 2.8em;
          }

          &__label {
            transition: opacity 0.3s ease-in-out;
            //transition-delay: 0.1s;
            opacity: 1;
            pointer-events: all;
          }
        }
      }
    }
  }
}
