.toolslist-item {
  td {
    vertical-align: middle;
  }
}

.toolslist-cart {
  margin-top: 1.5rem;

  @include media-breakpoint-up(lg) {
    position: absolute;
    right: 1.5rem;
    top: 0;
  }
}
