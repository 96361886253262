.project-table {
  font-size: $size-15;

  td {
    vertical-align: top !important;
  }

  tr:nth-child(2n) {
    // background: #f0f0f0;
  }

  &__small {
    font-size: 0.8em;
  }

  &__value {
    line-height: 2.45em;
  }
}

.project__actions-toggle {

  a {
    font-family: $font-family-sans-serif;
  }

  .btn-link {
    padding: 0;
    color: currentColor;
    &:hover {
      text-decoration: none;
    }

    i {
      display: block;
    }
  }

  .dropdown-item {
    padding: .25rem 1rem;

    i {
      margin-right: 1em;
    }
  }
}
