.cart-button {
  display: inline-block;
  position: relative;
  background-color: $minco-grass;
  color: white;
  font-family: "Proxima Nova Alt", sans-serif;
  font-weight: normal;

  padding: 1.2em 1em;

  &__icon {
    font-size: 2em;
    vertical-align: middle;
  }

  &__badge {
    display: block;
    text-align: center;
    font-size: 0.9em;
    color: $minco-grass;
    background-color: white;

    position: absolute;
    height: 1.4em;
    line-height: 1.4em;
    width: 1.4em;
    top: 0.8em;
    right: 0.5em;

    border-radius: 50%
  }
}
