.status {
  position: relative;
  &-accepted {
    background-color: #9fd36b;
    color: $minco-black-two;
  }
  &-assigned {
    background-color: #1c9cbc;
    color: $minco-white-three;
  }
  &-cancelled {
    background-color: #4f5259;
    color: $minco-white-three;
  }
  &-contacted {
    background-color: #97a1b2;
    color: $minco-black-two;
  }
  &-delegated {
    background-color: #41c3e3;
    color: $minco-black-two;
  }
  &-expired {
    background-color: #dc8911;
    color: $minco-black-two;
  }
  &-failed {
    background-color: $danger;
    color: $minco-black-two;
  }
  &-new {
    background-color: #57cae7;
    color: $minco-black-two;
  }
  &-declined {
    background-color: #f4d03f;
    color: $minco-black-two;
  }
  &-sent {
    background-color: #7a869c;
    color: $minco-black-two;
  }
  &-transformed {
    background-color: #5DDEFF;
    color: $minco-black-two;
  }
  &-succeeded {
    background-color: $minco-grass;
    color: $minco-white-three;
  }
  &-unassigned {
    background-color: #efa131;
    color: $minco-black-two;
  }
}

@import "list";
@import "show";