.project-list {

  .filter__input--text {
    .btn {
      display: inherit;
    }
  }

.projectlist-item {

  .projectlist__highlight {
    a {
      color: inherit;
    }
  }

  &--complete {
    .projectlist__highlight {
      color: #5eb245;
    }
  }

  &--pending {
    .projectlist__highlight {
      color: #e0161d;
    }
  }
}
}


.pagination > li > a,
.pagination > li > span{
  color: #000000;

}

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus{
  background-color: $minco-dark-blue;
  color:#ffffff;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus{
  background-color: $minco-dark-blue;
  color: #ffffff;
  border: 1px solid #dddddd;
}

.dropdown-item {
  @include hover-focus {
    color: $minco-white !important;
    text-decoration: none;
    @include gradient-bg($minco-dark-blue);
  }
  &.active,
  &:active {
    color: $dropdown-link-active-color;
    text-decoration: none;
    @include gradient-bg($minco-dark-blue);
  }
}