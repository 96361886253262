.innovations-hybride {
    &__bandeau
    {
        background-image: url('~/build/images/innovations/innovation-hybride-bandeau.jpg');
        background-size: cover;
        
        height: 27.5rem;
        padding:20px 18px 13px 26px;

        &__texte
        {
            border:solid 1px $minco-white;
            height: 25.3125rem;
            background-image: url("~/build/images/innovations/combined-shape-transparent.svg");
            background-position: center;
            background-repeat: no-repeat;
            vertical-align: middle;
            position:relative;
            width:100%;
            @media(max-width: 411px) {
                background-image: none;
                border:none;
            }
            h1{
                width:24.1875rem;
                color:$minco-white;
                text-align: center;
                margin-top:8.125rem;
                height:6.25rem;
            }
        }
    }
    p{
        font-size:0.9375rem;
    }

    .img-responsive
    {
        width:100%;
    }

    .hybride-img-right
    {
        @include media-breakpoint-up(xl) {
            margin-left: 0px; //-55
        }
        @include media-breakpoint-down(sm) {
            margin-left: 0;
        }
    }

}