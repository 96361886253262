.registration {
  &__show {
    .list-unstyled {
      li {
        line-height: 2;
        .icon {
          font-size: 1.2rem;
        }
      }
    }
    .switch {
      width: 170px;
      &-label {
        font-size: .7rem;
      }
      &-handle {
        &:before,
        &:after {
          font-size: .7rem;
        }
      }
    }
  }
}