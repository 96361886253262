.menu-toggle {
  display: inline-block;
  @include media-breakpoint-down(sm) {
    padding-right: 0;
  }
  &:hover {
    cursor: pointer;
  }
  &__line {
    background-color: $minco-black;
    display: block;
    height: 3px;
    margin: 6px 0;
    transition: all .3s ease-in-out;
    width: 1.8rem;
    @include media-breakpoint-down(sm) {
      height: 2px;
      margin: 5px 0;
      width: 1.5rem;
    }
  }
  &.is-active .menu-toggle__line {
    margin: 3px 0;
    @include media-breakpoint-down(sm) {
      margin: 4px 0;
    }
  }
  &.is-active .menu-toggle__line:nth-child(1),
  &.is-active .menu-toggle__line:nth-child(3){
    width: 1.2rem;
  }
  &.is-active .menu-toggle__line:nth-child(1){
    transform: translateX(-6px) rotate(-45deg);
    @include media-breakpoint-down(sm) {
      transform: translateX(-5px) rotate(-45deg);
    }
  }
  &.is-active .menu-toggle__line:nth-child(3){
    transform: translateX(-6px) rotate(45deg);
    @include media-breakpoint-down(sm) {
      transform: translateX(-5px) rotate(45deg);
    }
  }
}

.cadre-link-user
{
  //min-width: 155px;
  height: 2.9375rem;
  border-radius: 1.25rem;
  border: solid 1px #e6e6e6;
  padding:0.625rem;
  text-align: left;
  color: $minco-dark-blue;
  @media(max-width:480px){
    padding-left: 6rem;
    border:none;
    padding:0;
    border-radius: 0;
  }
}