.login {

  height: 100vh;
  @include media-breakpoint-down(sm) {
    height: auto;
    min-height: 100vh;
  }

  display: flex;
  flex-direction: column;

  &__logo {
    position: absolute;
    font-size: 420px;
    line-height: 0;
    //top: 0.075em;
    //left: 0.145em;
    text-align: center;
    display: inline-block;height: 0px;width: 0px;border-top: 12.5rem solid $minco-dark-blue;border-right: 12.5rem solid transparent;z-index: 1;
    @include media-breakpoint-down(lg) {
      font-size: 34vw;
    }

    @include media-breakpoint-down(sm) {
      font-size: 64vw;
    }

    &-image {
      width: 0.84em;
      height: auto;
      margin: 0 auto;
      position: absolute;
      z-index: 2;
      top: -8.375rem;
      left: 2.125rem;
    }

    .icon-hexagonal {
      color: white;
      position: absolute;
      left: -0.28em;
      top: -0.58em;
      z-index: 1;
      transform: rotate(35deg);
      transform-origin: center center;

      text-shadow: 2px 3px 7px rgba(0, 0, 0, 0.3);
    }
  }

  &__hero {
    background: url("~/build/images/login/background.jpg") no-repeat center bottom;
    background-size: cover;
    flex: 1;
    position: relative;

    @include media-breakpoint-down(sm) {
      height: 334px;
      background: url("~/build/images/login/background--mobile.jpg") no-repeat center bottom;
      background-size: cover;
    }
  }

  &__intro {
    position: absolute;
    font-size: 4.3vw;
    line-height: 1em;
    left: 3vw;
    bottom: 7vw;
    width: calc(49vw - 3vw);
    color: $minco-white;
    text-shadow: 2px 3px 7px rgba(0, 0, 0, 0.3);

    .icon {
      font-size: 0.8em;
      position: relative;
      top: -0.1em;
    }

    @include media-breakpoint-down(sm) {
      font-size: 7.3vw;
      width: calc(87vw - 3vw);
    }
  }

  &__container {
    background: white;
    position: relative;
    flex-direction: row;
    display: flex;

    padding: 0 1.5rem;

    @include media-breakpoint-down(sm) {
      display: block;
      padding: 0;
    }
  }

  &__message {
    position: absolute;
    top: -3em;
    width: 100%;
  }

  &__bloc {
    padding: 1.5rem;

    &--form {
      flex: 1;
      border-right: 1px solid $minco-pinkish-grey;

      @include media-breakpoint-down(sm) {
        border-right: none;
        border-bottom: 1px solid $minco-pinkish-grey;
      }
    }
  }

  &__title {
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: 0.6em;
  }

  &__form {
    display: flex;

    @include media-breakpoint-down(md) {
      display: block;
    }
  }

  &__field {
    flex: 1;
    padding-right: 1rem;

    @include media-breakpoint-down(md) {
      padding-right: 0;
      margin-bottom: 1rem;
    }
  }

  &__forgot {
    font-weight: 200;
    text-decoration: underline;
  }

  &__submit-cta {
    &:after {
      margin-left: 1em;
      color: $minco-pumpkin;
    }
  }

  &__register-cta {
    &:after {
      margin-left: 3em;
    }
  }

}
