.orders-list {

}
.order-table{
  font-size: 0.938rem;
  border-collapse: separate;
  border-spacing: 0 5px;

  td {
    word-break: break-word;
    border-top: 1px solid #E2E2E2;
    border-bottom: 1px solid #E2E2E2;

    padding: 1em;

    &:first-child {
      border-left: 1px solid #E2E2E2;
    }

    &:last-child {
      border-right: 1px solid #E2E2E2;
    }
  }
  .b-table-warning {
    background-color: #D10303 !important;
    color: white !important;
  }
}

.orderslist-item {
  cursor: pointer;

  .orderslist__cta {
    color: #e0161d;
  }

  .orderslist__highlight {
    a {
      color: inherit;
    }
  }

  &--complete {
    .orderslist__highlight {
      color: #5eb245;
    }
  }

  &--pending {
    .orderslist__highlight {
      color: #e0161d;
    }
  }

  &--warning {
    &:after {
      font-family: "minco-extranet" !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-variant: normal !important;
      text-transform: none !important;
      speak: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      content: "l";
      position: absolute;
      color: #e0161d;
      font-size: 1.4em;
      left: -0.3em;
    }
  }

  &--opened {
    background: $minco-white-three;
    border-bottom: none !important;
  }
}

tr.orderslist-details {
  display: none;

  &--opened {
    display: table-row;
    background: $minco-white-three;

    @include media-breakpoint-down(md) {
      display: block;
    }
  }
}

.orderslist-detail {

  & + & {
    margin-top: 2em;
    @include media-breakpoint-up(lg) {
      margin-top: 0;
      margin-left: 4em;
    }
  }
  &__steps {
    @include media-breakpoint-up(lg) {
      display: flex;
      width: 100%;
      gap: 2em;
      vertical-align: top;
    }
  }

  &__timeline {
    flex: 1;
  }

  &__actions {
    @include media-breakpoint-up(lg) {
      text-align: right;
    }
  }

  &__delivery-details {
    position: relative;
    display: inline-block;
    padding: 2em 6em 2em 2em;
    color: white;
    background: $minco-online;
  }

  &__delivery-edit {
    position: absolute;
    top: 1em;
    right: 1em;

    font-size: 1.2em;
    margin: 0;
    padding: 0;
    border: 1px solid currentColor;
    border-radius: 100%;
    width: 2em;
    height: 2em;
    color: white;
    background: none;
  }

  &__line {
    display: flex;
    width: 100%;
    border-top: 1px solid #D9D9D9;
    margin: 1.8em 0;
    padding: 1.8em 0 0 0;
  }

  &__line-desc {
    flex: 1;
    p {
      margin-bottom: 0.6em;
    }
  }

  &__line-status {
    color: $minco-online;
    font-weight: bold;
  }
  &__form {
    .vdp-datepicker, .time-picker {
      max-width: 7em;
      display: inline-block;
      margin-right: 2em;
    }

    .form-control:disabled, .form-control[readonly] {
      background-color: #ffffff;
      opacity: 1;
    }
  }

  &__remove-attachment {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    margin-left: 0em;
    font-size: 1.2em;
    padding: 0.6em;
  }
}

.order-table
{
  tr {
    cursor: pointer;
    position:relative;
    &:nth-child(even){
      //background-color:#0d5c8c ;
    }
    &:nth-child(odd){
      //background-color:#1c7430 ;
    }

    .showDetailsBtn{
      position:absolute;
    }
    &.b-table-has-details {
      border-bottom-color: transparent;
      background-color: $minco-white-three;
    }

  }
}
