#minco-body {
  color:$minco-grey;
  .project-index {
    &__tools {
      .title-3 {
        font-family: "Poppins regular", sans-serif;
        font-size: 1rem;
        @include media-breakpoint-up(lg) {
          font-size: 1.7vw;
        }
        font-weight: 400;
        text-align: center;
      }
      .title-4
      {
        font-family: "Poppins regular", sans-serif;
        font-size: 1rem;
        @include media-breakpoint-up(lg) {
          font-size: 1.5vw;
        }
        font-weight: 400;
        text-align: center;
      }
    }
    &__summary {
      &__left {
        text-align: center;

        @include media-breakpoint-up(lg) {
          .title-2 {
            font-size: 2.5vw;
          }
        }

        .title-3 {
          font-family: "Poppins regular", sans-serif;
          font-size: 2.1875rem;
          @include media-breakpoint-up(lg) {
            font-size: 1.875vw;
          }
          font-weight: 700;
          text-align: center;
        }

        p{
          font-size: 1.125rem;
        }
      }
      &__right{
        .bg-dark-blue {
          color: $minco-white;
          @include media-breakpoint-up(lg) {
            font-size: 1vw;
            .title-2 {
              font-size: 2vw;
            }
            .title-3 {
              font-size: 1.9vw;
            }
          }
          .icon {
            font-size: 3.45rem;
            @include media-breakpoint-between(md, lg) {
              font-size: 2.45rem;
            }
          }
          .list-unstyled.text-right {
            font-family: "Poppins regular", sans-serif;
            font-weight: 600;
          }
        }
      }
    }
  }
}
