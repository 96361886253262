.simple-table {

  & > tbody > tr > [data-label] {
    align-items: center;
  }

  @include media-breakpoint-up(lg) {
    thead th {
      background-color: $minco-dark-blue;
      color: $minco-white;
      font-weight: normal;
    }

    tr {
      border-bottom: 1px solid $minco-white-two;
    }

    th {
      //white-space: nowrap;
    }

    th,
    td {
      padding: .75em .5rem;
      border-top: none;
    }

    td {
      vertical-align: middle;
    }
  }

  &__element-container {
    padding: 1em;
    color: #000;

    span {
      display: block;
    }

    &--white {
      color: white;
    }
  }
}

@media print {
  .simple-table {
    & > tbody > tr > [data-label] {
      align-items: center;
    }
    thead th {
      background-color: $minco-dark-blue;
      color: $minco-white;
      font-weight: normal;
    }

    tr {
      border-bottom: 1px solid $minco-white-two;
    }

    th {
      //white-space: nowrap;
    }

    th,
    td {
      padding: .75em .5rem;
      border-top: none;
    }

    td {
      vertical-align: middle;
    }
  }
}