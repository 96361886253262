.cart-navbar {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  list-style: none;

  padding: 0;

  border-radius: 35px;
  border: solid 1px #d5d5d5;

  counter-reset: flag;

  &:after {
    content: '\0020';
    display: block;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    bottom: 0;
    background: white;
    border-radius: 35px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }

  &--step1 {
    &:after {
      width: 25%;
    }
  }

  &--step2 {
    &:after {
      width: 50%;
    }
  }

  &--step3 {
    &:after {
      width: 75%;
    }
  }

  &--step4 {
    &:after {
      width: 100%;
    }
  }

  &__item {
    display: inline-block;
    position: relative;
    z-index: 1;
    flex-grow: 1;
    flex-basis: 0;
    text-align: center;
    color: $minco-grass;
    line-height: 1.25;

    cursor: pointer;

    &:before {
      content: counter(flag);
      display: inline-block;
      counter-increment: flag;
      border-radius: 100%;
      color: white;
      background-color: $minco-grass;
      height: 1.25em;
      width: 1.25em;
      line-height: 1.25em;
      margin-right: 0.3em;
    }

    &--disabled {
      color: #9b9b9b;
      cursor: default;
      pointer-events: none;

      &:before {
        background-color: #9b9b9b;
      }
    }
  }

  &__link {
    display: inline-block;
    cursor: inherit;
    padding: 0.5em 0;
    font-family: inherit;
    font-weight: inherit;
  }
}
