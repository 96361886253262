.innovations-dna {
  
  &__dates {
    font-size: 0;

    & > * {
      position: relative;
    }
  }

  &__date {
    vertical-align: top;
    display: inline-block;
    position: relative;

    &--1 {
      width: 100%;
      z-index: 1;
      @include media-breakpoint-up(lg) {
        width: 70%;
      }
    }

    &--2 {
      z-index: 0;
      width: 100%;

      @include media-breakpoint-up(lg) {
        position: absolute;
        width: 40%;
        right: 0;
      }
    }
  }
}
