.timeline {
    display: flex;
    position: relative;
    flex-direction: column;
    list-style: none;
    margin-top: 1.8em;

    @include media-breakpoint-up(md) {
        flex-direction: row;
        justify-content: space-between;
        text-align: center;
    }

    &:after {
        content: '';
        background-color: $minco-online;
        position: absolute;
        left: calc(25px /2);
        top: 5%;
        width: 1px;
        height: 90%;
        
        @include media-breakpoint-up(md) {
            width: calc(80% + 4em);
            height: 2px;
            top: -1em;
            left: calc(10% - 2em);
        }
    }

    &__item {
        position: relative;
        width: 100%;
        font-size: 13px;
        padding: 10px 0;
        padding-left: calc(25px + 1em);
        
        @include media-breakpoint-up(md) {
            padding-left: 0;
            width: calc((100% / 6) - 2em);
        }

        &:before,
        &:after {
            position: absolute;
            width: 25px;
            height: 25px;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            
            @include media-breakpoint-up(md) {
                top: -2em;
                left: 50%;
                transform: translateX(-50%);
            }
        }
        
        &:after {
            content: '';
            background-color: white;
            border: 2px solid $minco-online;
            border-radius: 100%;
            z-index: 2;
        }

        &:before {
            content: '\e901';
            font-family: 'icomoon';
            color: transparent;
            z-index: 3;
            font-size: 1.2em;
            left: 5px;

            @include media-breakpoint-up(md) {
                left: 50%;
                top: -23px;
            }
        }

        &--active {
            &:after {
                background-color: $minco-online;
            }

            &:before {
                color: white;
            }
        }

        &--warning {
            color: #D10303;

            &:after {
                background-color: #D10303;
                border: 2px solid #D10303;
            }

            &:before {
                color: transparent;
            }
        }
    }
}
