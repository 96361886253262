.library {
  &__listed {
    .table-filters {
      background: none;
      display: flex;
      flex-flow: row wrap;
      @include media-breakpoint-up(lg) {
        margin: 0 -0.75rem;
      }

      &:after {
        content: "";
        flex: auto;
      }

      .table-filter {
        width: 100%;
        @include media-breakpoint-up(lg) {
          width: 25%;
          padding: 0 0.75rem;
        }
      }
      .table-filter:first-of-type {
        width: 100%;
      }
    }

    .responsive-table {
      thead th {
        background: $minco-white;
        color: $minco-black;
        font-weight: bold;
      }

      &__sortable:after {
        border-right-color: $minco-black;
        border-bottom-color: $minco-black;
      }
    }

    .media {
      &__download {
        display: inline-block;

        img {
          height: 34px;
          width: auto;
        }

        & + .media__download {
          margin-left: 1em;
        }
      }
    }
  }
}
