.address-list {
  list-style: none;
  background-color: $minco-white-three;
  
  &__name {
    display: inline-block;
    font-size: $size-20;
    vertical-align: middle;
    margin-bottom: 1em;
  }

  &__label {
    padding: 0 1em;
  }

  &__item {
    padding: 1.2em;
  }

  &__delete {
    margin-top: 2em;
  }
}
