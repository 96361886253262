@import "~vue2-timepicker/dist/VueTimepicker.css";
.time-picker input.display-time {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 1rem;
}

.vue__time-picker .dropdown ul li:not([disabled]).active {
  background-color: $minco-pumpkin;
}

