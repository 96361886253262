.ct-app {
  opacity: 0;
  pointer-events: none;
  &--enabled {
    opacity: 1;
    pointer-events: all;
  }
}

.ct-widget.ct-ignition {
    right: 3.5rem !important;
    left: auto !important;
    top: 5rem!important;
    z-index: 9;

    &--editing {
      right: 8rem !important;
    }
}
