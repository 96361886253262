.registration {

  label {
    font-family: "Poppins regular", sans-serif;
    font-weight: bold;
  }

  &__subtitle {
    font-size: 1.6rem;
    font-family: "Poppins regular", sans-serif;
    font-weight: bold;
  }

  &__header {
    font-size: 1.3rem;
    font-family: "Poppins regular", sans-serif;
    font-weight: bold;
  }

  &__notice {
    color: #373a3c;
  }

  &__confirmation {
    position: relative;
  }
}
