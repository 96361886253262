.quote-request {
  &__show {
    .quote-request {
      &__actions {
        .action__buttons {
          @include media-breakpoint-down(sm) {
            padding-top: 1rem;
          }
          .btn {
            color: $white;
            font-weight: bold;
            padding-left: 1rem;
            padding-right: 1rem;
            @include media-breakpoint-down(sm) {
              width: 80%;
            }
            & + .btn {
              @include media-breakpoint-down(sm) {
                margin-top: .5rem;
              }
            }
          }
        }
      }
      &__project {
        &__title {
          font-weight: bold;
        }
        &__details {
          background-color: $minco-white-three;
          font-weight: bold;
          text-align: center;
        }
      }
      &__history {
        &__title {
          font-weight: bold;
        }
        .history {
          &__list {
            &__item {
              border-top: 1px solid #d8d8d8;
              padding-bottom: .5rem;
              padding-top: .5rem;
            }
          }
        }
      }
    }
    .list-unstyled {
      li {
        line-height: 2;
        .icon {
          font-size: 1.2rem;
        }
      }
    }
    .switch {
      width: 170px;
      &-label {
        font-size: .7rem;
      }
      &-handle {
        &:before,
        &:after {
          font-size: .7rem;
        }
      }
    }
  }
}