/*♪┏(°.°)┛┗(°.°)┓♪ Variables ♪┏(°.°)┛┗(°.°)┓┗♪*/
$minco-azure:                  #009fe3;
$minco-black:                  #000000;
$minco-black-two:              #242424;
$minco-dark-lilac:             #8967aa;
$minco-grass:                  #63b32e;
$minco-pinkish-grey:           #e6e3e6;
$minco-pumpkin:                #f07d00;
$minco-purple-grey:            #847683;
$minco-warm-grey:              #767676;
$minco-white:                  #ffffff;
$minco-white-two:              #d8d8d8;
$minco-white-three:            #f0f0f0;
$minco-white-four:             #f3eeee;
$minco-light-grey:             #f4f3f4;
$warning:                       #e0161d;
$success:                       #5eb245;
$minco-yellow:                  #f5a22b;
$minco-dark-blue:              #005a63;
$minco-innovation:             #c6b4a2;
$minco-box-grey:              #6d7278;
$minco-online:                #015962;
$minco-indispensalbe:         #e8b85e;
$minco-lighter-grey:           #f0f1f3;
$minco-grey:                   #727172;

/*♪┏(°.°)┛┗(°.°)┓♪ Classes ♪┏(°.°)┛┗(°.°)┓┗♪*/
.text-azure {
  color: $minco-azure !important;
}
.text-black {
  color: $minco-warm-grey !important;
}
.text-darker
{
  color: $minco-black !important;
}
.text-dark-lilac {
  color: $minco-dark-lilac !important;
}
.text-black-two {
  color: $minco-black-two !important;
}
.text-grass {
  color: $minco-grass !important;
}
.text-pumpkin {
  color: $minco-pumpkin !important;
}
.text-pinkish-grey {
  color: $minco-pinkish-grey !important;
}
.text-purple-grey {
  color: $minco-purple-grey !important;
}
.text-warm-grey {
  color: $minco-warm-grey !important;
}
.text-white {
  color: $minco-white !important;
}
.text-white-two {
  color: $minco-white-two !important;
}
.text-white-three {
  color: $minco-white-three !important;
}
.text-white-four {
  color: $minco-white-four !important;
}
.text-dark-blue{
  color:$minco-dark-blue;
}

.bg-azure {
  background-color: $minco-azure !important;
}
.bg-yellow {
  background-color: $minco-yellow !important;
}
.bg-black {
  background-color: $minco-black !important;
}
.bg-dark-lilac {
  background-color: $minco-dark-lilac !important;
}
.bg-black-two {
  background-color: $minco-black-two !important;
}
.bg-grass {
  background-color: $minco-grass !important;
}
.bg-pinkish-grey {
  background-color: $minco-pinkish-grey !important;
}
.bg-pumpkin {
  background-color: $minco-pumpkin !important;
}
.bg-purple-grey {
  background-color: $minco-purple-grey !important;
}
.bg-warm-grey {
  background-color: $minco-warm-grey !important;
}
.bg-white {
  background-color: $minco-white !important;
}
.bg-white-two {
  background-color: $minco-white-two !important;
}
.bg-white-three {
  background-color: $minco-white-three !important;
}
.bg-white-four {
  background-color: $minco-white-four !important;
}
.bg-dark-blue
{
  background-color: $minco-dark-blue;
}
.bg-lighter-grey
{
  background-color: $minco-lighter-grey;
}
.bg-indispensalbe
{
  background-color: $minco-indispensalbe;
}

.border-azure {
  border-color: $minco-azure !important;
}
.border-black {
  border-color: $minco-black !important;
}
.border-dark-lilac {
  border-color: $minco-dark-lilac !important;
}
.border-black-two {
  border-color: $minco-black-two !important;
}
.border-grass {
  border-color: $minco-grass !important;
}
.border-pinkish-grey {
  border-color: $minco-pinkish-grey !important;
}
.border-pumpkin {
  border-color: $minco-pumpkin !important;
}
.border-purple-grey {
  border-color: $minco-purple-grey !important;
}
.border-warm-grey {
  border-color: $minco-warm-grey !important;
}
.border-white {
  border-color: $minco-white !important;
}
.border-white-two {
  border-color: $minco-white-two !important;
}
.border-white-three {
  border-color: $minco-white-three !important;
}
.border-white-four {
  border-color: $minco-white-four !important;
}

.color_innovation
{
  color: $minco-innovation;
}

.color_online
{
  color: $minco-online;
}

.color_indispensalbe
{
  color: $minco-indispensalbe;
}

.cercle-orange
{
  width: 40px;
  height: 40px;
  background-color: #e8b85e;
  border-radius: 20px;
  position:absolute;
  bottom:8.4375rem;
  color:$minco-white;
  font-size: 2.1875rem;
  text-align:center;
  padding-top: 5px;
  @media (max-width: 411px) {
    bottom:7.8375rem;

  }
}
