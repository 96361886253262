/*♪┏(°.°)┛┗(°.°)┓♪ Fontfaces ♪┏(°.°)┛┗(°.°)┓┗♪*/
@font-face {
  font-family: 'Poppins regular';
  src: url('~/build/fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
	font-family: "Poppins bold";
	src: url("~/build/fonts/Poppins-Bold.ttf");
	src: local("☺"),
    url("~/build/fonts/Poppins-Bold.ttf") format("truetype");
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: "Proxima Nova";
	src: url("~/build/fonts/Proxima Nova Bold It.eot");
	src: local("☺"),
    url("~/build/fonts/Proxima Nova Bold It.svg") format("svg"),
    url("~/build/fonts/Proxima Nova Bold It.ttf") format("truetype"),
    url("~/build/fonts/Proxima Nova Bold It.woff") format("woff");
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: "Poppins regular";
	src: url("~/build/fonts/Poppins-Regular.ttf");
	src: local("☺"),
    url("~/build/fonts/Poppins-Regular.ttf") format("truetype");
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: "Proxima Nova Light";
	src: url("~/build/fonts/Proxima Nova Light It.eot");
	src: local("☺"),
    url("~/build/fonts/Proxima Nova Light It.svg") format("svg"),
    url("~/build/fonts/Proxima Nova Light It.ttf") format("truetype"),
    url("~/build/fonts/Proxima Nova Light It.woff") format("woff");
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: "Poppins regular";
	src: url("~/build/fonts/Proxima Nova Reg.eot");
	src: local("☺"),
    url("~/build/fonts/Poppins-Regular.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Proxima Nova";
	src: url("~/build/fonts/Proxima Nova Reg It.eot");
	src: local("☺"),
    url("~/build/fonts/Proxima Nova Reg It.svg") format("svg"),
    url("~/build/fonts/Proxima Nova Reg It.ttf") format("truetype"),
    url("~/build/fonts/Proxima Nova Reg It.woff") format("woff");
	font-weight: normal;
	font-style: italic;
}

@font-face {
  font-family: 'Poppins regular';
  src: url('~/build/fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
/*♪┏(°.°)┛┗(°.°)┓♪ Fonticons ♪┏(°.°)┛┗(°.°)┓┗♪*/
@font-face {
  font-family: "minco-extranet";
  src:url("~/build/fonts/minco-extranet.eot");
  src:url("~/build/fonts/minco-extranet.eot?#iefix") format("embedded-opentype"),
  url("~/build/fonts/minco-extranet.woff") format("woff"),
  url("~/build/fonts/minco-extranet.ttf") format("truetype"),
  url("~/build/fonts/minco-extranet.svg#minco-extranet") format("svg");
  font-weight: normal;
  font-style: normal;

}
[data-icon],
[class^="icon-"],
[class*=" icon-"] {
  display: inline-block;
  font: normal normal normal 14px/1 "minco-extranet";
  font-size: inherit;
}
[data-icon]:before {
  display: block;
  font-family: "minco-extranet" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^="icon-"]:before,
[class*=" icon-"]:before {
  display: block;
  font-family: "minco-extranet" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-angle-down:before {
  content: "\61";
}
.icon-angle-up:before {
  content: "\62";
}
.icon-idea-2:before {
  content: "\63";
}
.icon-calculator:before {
  content: "\64";
}
.icon-calendar:before {
  content: "\65";
}
.icon-checkbox-pen-outline:before {
  content: "\66";
}
.icon-close:before {
  content: "\67";
}
.icon-coins:before {
  content: "\68";
}
.icon-cube:before {
  content: "\69";
}
.icon-duplicate-image:before {
  content: "\6a";
}
.icon-envelope:before {
  content: "\6b";
}
.icon-envelope-outline:before {
  content: "\6c";
}
.icon-euro:before {
  content: "\6d";
}
.icon-excel:before {
  content: "\6e";
}
.icon-exclamation-mark:before {
  content: "\6f";
}
.icon-expand:before {
  content: "\70";
}
.icon-file:before {
  content: "\71";
}
.icon-file-euro:before {
  content: "\72";
}
.icon-files-and-folders:before {
  content: "\73";
}
.icon-group:before {
  content: "\74";
}
.icon-growth:before {
  content: "\75";
}
.icon-help:before {
  content: "\76";
}
.icon-hexagonal:before {
  content: "\77";
}
.icon-hexagonal-outline:before {
  content: "\78";
}
.icon-home:before {
  content: "\79";
}
.icon-idea:before {
  content: "\7a";
}
.icon-law:before {
  content: "\42";
}
.icon-left:before {
  content: "\43";
}
.icon-left-arrow:before {
  content: "\44";
}
.icon-magnifier:before {
  content: "\45";
}
.icon-menu-dropdown:before {
  content: "\46";
}
.icon-newspaper-folded:before {
  content: "\47";
}
.icon-next:before {
  content: "\48";
}
.icon-old-fashion-briefcase:before {
  content: "\49";
}
.icon-package:before {
  content: "\4a";
}
.icon-pdf:before {
  content: "\4b";
}
.icon-pencil:before {
  content: "\4c";
}
.icon-phone:before {
  content: "\4d";
}
.icon-photo:before {
  content: "\4e";
}
.icon-plane:before {
  content: "\4f";
}
.icon-quarter:before {
  content: "\50";
}
.icon-recycle-sign:before {
  content: "\51";
}
.icon-right:before {
  content: "\52";
}
.icon-right-arrow:before {
  content: "\53";
}
.icon-shape:before {
  content: "\54";
}
.icon-share:before {
  content: "\55";
}
.icon-shopping-cart:before {
  content: "\56";
}
.icon-shopping-cart-outline:before {
  content: "\57";
}
.icon-smartphone:before {
  content: "\58";
}
.icon-stamp:before {
  content: "\59";
}
.icon-star:before {
  content: "\5a";
}
.icon-star-outline:before {
  content: "\30";
}
.icon-switch:before {
  content: "\31";
}
.icon-telephone:before {
  content: "\32";
}
.icon-unswitch:before {
  content: "\33";
}
.icon-user:before {
  content: "\34";
}
.icon-user-cross:before {
  content: "\35";
}
.icon-verified:before {
  content: "\36";
}
.icon-waste-bin:before {
  content: "\37";
}
.icon-wifi:before {
  content: "\38";
}
.icon-word:before {
  content: "\39";
}
.icon-bim:before {
  content: "\22";
}
.icon-idea-2-1:before {
  content: "\41";
}
.icon-tablet:before {
  content: "\23";
}
.icon-wrench:before {
  content: "\21";
}

.icon-gold-shape-outline:before
{
  content:url("~/build/images/fonticons/shape-gold.svg");
}


@font-face {
  font-family: 'icomoon';
  src:  url('~/build/fonts/icomoon.eot?vd42h2');
  src:  url('~/build/fonts/icomoon.eot?vd42h2#iefix') format('embedded-opentype'),
  url('~/build/fonts/icomoon.ttf?vd42h2') format('truetype'),
  url('~/build/fonts/icomoon.woff?vd42h2') format('woff'),
  url('~/build/fonts/icomoon.svg?vd42h2#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icomoon-"], [class*=" icomoon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icomoon-calendar:before {
  content: "\e900";
}
.icomoon-check:before {
  content: "\e901";
}
.icomoon-chevron-down:before {
  content: "\e902";
}
.icomoon-chevron-left:before {
  content: "\e903";
}
.icomoon-chevron-right:before {
  content: "\e904";
}
.icomoon-clock:before {
  content: "\e905";
}
.icomoon-close:before {
  content: "\e906";
}
.icomoon-delivery:before {
  content: "\e907";
}
.icomoon-info:before {
  content: "\e908";
}
.icomoon-Link:before {
  content: "\e909";
}
.icomoon-menu:before {
  content: "\e90a";
}
.icomoon-trash:before {
  content: "\e90b";
}

/*♪┏(°.°)┛┗(°.°)┓♪ Font Sizes ♪┏(°.°)┛┗(°.°)┓┗♪*/
$size-8: .5rem;
$size-11: .7rem;
$size-12: .75rem;
$size-14: .875rem;
$size-15: .938rem;
$size-16: 1rem;
$size-18: 1.125rem;
$size-20: 1.25rem;
$size-25: 1.563rem;
$size-28: 1.75rem;
$size-30: 1.875rem;
$size-40: 2.5rem;
$size-44: 2.75rem;
$size-50: 3.125rem;
/*♪┏(°.°)┛┗(°.°)┓♪ Font families ♪┏(°.°)┛┗(°.°)┓┗♪*/
