.innovations-passive {
  color:$minco-grey;
  font-size:15px;
  h4{
    color: $minco-dark-blue;
  }
  .h5-innovation, h5{
    color:$minco-black;

  }
  .h5-innovation:before{
    color: #ffffff;
    content: "S";
    display: inline-block;
    font-family: 'minco-extranet';
    font-size: 0.938rem;
    vertical-align: middle;
    border-radius: 9.5px;
    background-color: #005962;
    margin-right: 15px;
    maring-left: -45px;
    width: 30px;
    height: 20px;
    text-align: center;
    padding-top:4px;
  }

  &__bandeau
  {
    background-image: url('~/build/images/innovations/bandeau-innovation-passive.jpg');
    background-size: cover;

    height: 27.5rem;
    padding:20px 18px 13px 26px;
    &__texte
    {
      border:solid 1px $minco-white;
      height: 25.3125rem;
      background-image: url("~/build/images/innovations/combined-shape-transparent.svg");
      background-position: center;
      background-repeat: no-repeat;
      vertical-align: middle;
      position:relative;
      width:100%;
      @media(max-width: 411px) {
        background-image: none;
        border:none;
      }

      h1{
        width:24.1875rem;
        color:$minco-white;
        text-align: center;
        margin-top:8.125rem;
      }
    }
  }
}