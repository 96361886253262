.bim-objet {
  p{
    font-size:0.9375rem;
  }

  .img-responsive
  {
    width:100%;
  }

  .h5-bim
  {
    font-size: 0.938rem !important;
    color:$minco-black;
    padding-left: 10px;
    display: inline-block;
  }

  .h5-bim:before
  {
    color: #ffffff;
    content: "S";
    display: inline-block;
    font-family: 'minco-extranet';
    font-size: 0.938rem;
    vertical-align: middle;
    border-radius: 9.5px;
    background-color: #005962;
    margin-right: 10px;
    maring-left: -45px;
    width: 30px;
    height: 20px;
    text-align: center;
    padding-top:2px;
  }
}