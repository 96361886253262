#minco-body {
  color: $minco-dark-blue;
  .homepage {

    &__swiper {
     /* height: 41vw;*/

      @include media-breakpoint-down(sm) {
        height: 81vw;
      }
    }

    &__features {
      position: relative;
      z-index: 5;

      max-width: 1561px;
      margin: 2em auto 0;

      @include media-breakpoint-down(sm) {
        margin: 1.5em auto 0;
      }
      
      &__box {
        position: relative;
        //box-shadow: .4rem .4rem 1.6rem 0 rgba($minco-black, .5);
        color: $minco-box-grey;
        padding: 1.7rem 0.5rem 2rem 8rem;
        @include media-breakpoint-down(lg) {
          padding:1.7rem 0.5rem 2rem 4rem;
        }
        @include media-breakpoint-down(md) {
          padding: 1rem 0.5rem 1rem 1rem
        }

        @include media-breakpoint-down(sm) {
          //box-shadow: .4rem .4rem .8rem 0 rgba($minco-black, .5);
          margin: .5rem 0;
        }
        .list-chevron {

          &__title {
            display: block;
            font-size: 1.5rem;
            font-weight: bold;
            padding-left: 3rem;
            @include media-breakpoint-down(md) {
              padding-left: 0rem !important;
              
            }
            @include media-breakpoint-down(lg) {
              font-size: 2vw;
              
            }
            @include media-breakpoint-down(sm) {
              font-size: 6.3vw;
              @media (orientation: landscape) {
                font-size: 3.3vw;
              }
            }

            .icon {
              position: absolute;
              top: 1rem;
              left: 1rem;
              /*transform: rotate(23deg);*/
              font-size: 4rem;
              transform-origin: center center;
              color: $minco-white;
              @include media-breakpoint-down(lg) {
                font-size: 4vw;
              }

              @include media-breakpoint-down(sm) {
                font-size: 3.6rem;
                top: calc(50% - 2rem);
              }


              &-idea {
                font-size: 2.44rem;
              }
              &-share {
                font-size: 2.16rem;
              }
              &-star-outline {
                font-size: 2.389rem;
              }
            }
          }
          > li {
            font-size: 0.875rem;
            padding: 0.1em 0;
            @include media-breakpoint-between(md, lg) {
              font-size: 1.83vw;
            }
            @include media-breakpoint-down(sm) {
              font-size: 3.83vw;
              @media (orientation: landscape) {
                font-size: 1.83vw;
              }

            }
            @media(max-width:480px){
              padding-left: 6rem;
            }
          }
        }
      }
    }
    &__multimaterials {
      max-width: 95.813rem;
      margin: 0 auto;
      height:26.438rem;
      background-image: url('~/build/images/homepage/fond-menuiserie-hybride.jpg');
      background-size:cover;
      background-repeat: no-repeat;
     
      @include media-breakpoint-down(sm) {
        height:26.438rem;
      }

      &__image {
        @include media-breakpoint-down(sm) {
          text-align: center;
        }
      }
      &__text {
        margin-left:auto;
        margin-right:auto;
        color:$minco-white;
        padding-top:7.4rem;
        padding-left:5.688rem;
        font-size:1.875rem;
        height: 24.975rem;;
        @include media-breakpoint-down(sm) {
          text-align: center;
        }
        @include media-breakpoint-down(lg) {
          padding-top:0;
        }
        p {
          @include media-breakpoint-down(sm) {
            text-align: left;
          }
          a{
            text-transform: inherit;
            position: inherit;
            font-weight:inherit;
            font-size:inherit;
          }
        }
        a {
          font-size:0.938rem;
          text-transform: uppercase;
          position: absolute;
          bottom:1.875rem;
          right:4.688rem;
          @include media-breakpoint-down(sm) {
            margin: auto;
            position: relative;
          }
        }
      }
    }
    &__minco-online {
      position: relative;
      max-width: 1852px;
      //margin: 0 auto;

      &__box {
        &--activities {
          .list-unstyled {
            padding: .5rem .75rem;
          }
          .homepage__minco-online__box__title {

            @include media-breakpoint-between(md, lg) {
              //height: 26.3125rem;
            }
            @include media-breakpoint-down(sm) {
              //height: 1.5rem;
            }
          }
          &--link{
            padding:0.8125rem 1.5rem 0.75rem 1.5625rem;
            border-radius: 1.375rem;
            background-color: $minco-yellow;
            display: inline-block;
          }
          @include media-breakpoint-up(md)
          {
            //height:26.3125rem;
          }
        }
        &--opportunities {
          .homepage__minco-online__box__number {
            @include media-breakpoint-down(sm) {
              font-size: 10vw;
            }
          }
        }
        > ul {
          text-align: center;
          &:not(.bg-white) {
            color: $minco-white;
          }
        }
        &__title {
          font-size: 1.4rem;
          font-weight: 600;

          @include media-breakpoint-down(lg) {
            font-size: 1.5vw;
          }
          @include media-breakpoint-down(sm) {
            font-size: 4.5vw;
            @media (orientation: landscape) {
              font-size: 2vw;
            }
          }
        }
        &__number {
          font-size: 2.6vw;
          font-weight: bold;
          @include media-breakpoint-between(lg, md) {
            font-size: 4.6vw;
          }
          @include media-breakpoint-down(sm) {
            font-size: 10vw;
          }
          .icon {
            opacity: .6;
            &-shopping-cart {
              font-size: 3.16rem;
            }
            &-files-and-folders {
              font-size: 2.77rem;
            }
            @include media-breakpoint-down(sm) {
              font-size: 10vw;
            }
          }
        }
        &__link {
          a {
            color: $minco-white;
          }
        }
      }
      &__image {
        margin-left: 2rem;
        position: relative;
        width: 27.8vw;
        margin-top: 4rem ;

        &:before {
          top: -0.6em;
          color: #5b3b91;
          content: "t";
          font-family: "minco-extranet";
          font-size: 11.5rem;
          left: -0.95rem;
          opacity: .75;
          position: absolute;
          transform: rotate(14deg);

          @include media-breakpoint-down(lg) {
            top: -0.6em;
            font-size: 11.5rem;
            left: -0.95rem;
          }

          @include media-breakpoint-down(md) {
            font-size: 17.5rem;
            top: 0.4em;
            left: 23.05rem;
          }

          @include media-breakpoint-down(sm) {
            top: -4rem;
            left: 0.75rem;
            font-size: 7.5rem;
          }
        }
      }
    }

    &__information {
      text-align: center;
      max-width: 1366px;
      margin: 4rem auto 6rem;

      @include media-breakpoint-down(sm) {
        margin: 2rem auto 4rem;
      }

      div.icon {
        position: relative;

        &:before {
          position: relative;
          z-index: 2;
        }

        &-hexagonal-outline {
          font-size: 5.222rem;
        }
        i.icon {
          left: 0;
          margin: auto;
          position: absolute;
          z-index: 1;
          right: 0;
          top: 50%;
          transform: translateY(-55%);
          &-file {
            font-size: 2.15rem;
          }
          &-law {
            font-size: 2.55rem;
          }
          &-stamp {
            font-size: 2.65rem;
          }
          &-wrench {
            font-size: 2.2rem;
          }
        }

        i.icon-hexagonal {
          transform: none;
          top: 0;
        }
      }
      &__title {
        font-family: "Poppins regular", sans-serif;
        font-weight: 600;
        height: 3.5rem;
        text-transform: uppercase;
        color: $minco-black !important;
        @include media-breakpoint-down(lg) {
          font-size: $size-16;
        }
        @include media-breakpoint-down(sm) {
          height: 3.5rem;
        }
      }
      &__link {
        color: $minco-box-grey !important;
        font-size: $size-15;

      }
    }
    &__news {
      position: relative;

      &__card {
        position: relative;

        .nav-tabs {
          border-bottom: 4px solid $minco-dark-blue;

           justify-content: center;

          @include media-breakpoint-down(md) {
            justify-content: left;
          }

          @include media-breakpoint-down(sm) {
            // justify-content: center;
          }

          .nav-item {
            border: 1px solid $minco-dark-blue;
            background: $minco-white;
            border-bottom: transparent;
            text-align: center;

            .nav-link {
              color: $minco-black;
              font-size: $size-16;
              height: 100%;
              font-weight: normal;
              &.active {
                background-color: $minco-dark-blue;
                border-color: $minco-dark-blue;
                color: $minco-white;
                font-weight: 500;
              }

              @include media-breakpoint-down(sm) {
                font-size: $size-18;
              }
            }
          }
        }
        .card {
          &-title {
            font-family: "Poppins regular", sans-serif;
            font-weight: 600;
            color:$minco-dark-blue;
            font-size:$size-18;
          }
          &-text{
            font-weight: normal;
          }
          &-img-top {
            padding: 5px;
          }
        }
      }
      .position-md-absolute {
        top: 2.75rem;
        z-index: 1;
        @include media-breakpoint-between(md, lg) {
          top: 0;
        }
        @include media-breakpoint-down(sm) {
          top: .5rem;
        }
      }

      &-content {
        background: $minco-light-grey;
        margin-left: -1rem;
        margin-right: -1rem;
        padding-top:2.1875rem;

        & > * {
          max-width: 1366px;
          margin: 0 auto;
        }
      }

      &-link {
          position: absolute;
          right: 0;
          top: 0.5rem;
          //border-radius: 22px;
          color: $minco-dark-blue;
          text-align: center;
          padding-top: 11px;
          padding-bottom: 12px;
          height: 44px;
          width:252px;

          @include media-breakpoint-down(sm) {
            display: none;
            &--mobile {
              position: relative;
              display: block;
              text-align: right;
            }
          }
      }
    }
  }
  .bg-innovation
  {
    background-image: url("~/build/images/homepage/idea.svg");
    background-repeat: no-repeat;
    background-position: top left;
    background-color: #e6e6e6;
    background-size: 36%;
  }

  .bg-online
  {
    background-image: url("~/build/images/homepage/online.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #e6e6e6;
    background-size: 36%;

  }
  .bg-indispensable
  {
    background-image: url("~/build/images/homepage/indispensable.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #e6e6e6;
    background-size: 36%;
  }

  .bg-texte-menuiserie-hybride
  {
    
    background-image: url("~/build/images/homepage/fond-texte-menuiserie-hybride.png");
    background-repeat: no-repeat;
    @include media-breakpoint-down(lg) {
      background: none;
      }
    @include media-breakpoint-down(sm) {
    background: none;
    }
  }

  .bg-minco-online
  {
    background-image: url("~/build/images/homepage/fond-minco-online.png");
    background-repeat: no-repeat;
    @include media-breakpoint-down(sm) {
      background-image: none;
      background-color: #e6e6e6;
    }
  }

  h3.title-minco-online:before
  {
    //-webkit-clip-path: polygon(0 45%, 100% 45%, 100% 100%, 0% 100%);
    //clip-path: polygon(0 45%, 100% 45%, 100% 100%, 0% 100%);
    background-color:$minco-yellow;
    width:21px;
    height: 14px;
    display: inline-block;
    content:'';
    margin-right: 19px;
  }
  .title-minco-online
  {
    color: $minco-white;
    font-size:1.25rem;
  }

  .number
  {
    font-size:3.125rem;
  }

.card-img-overlay
{
  width: 22.4375em;
  height: 8.875rem;
  background-color: $minco-white;
  margin-top:13.5em;
  

  .arrowed-link-overlay
  {
    position: absolute;
    bottom: 8.0rem;
    right: 0rem;
  }
  .arrowed-link-overlay:before
  {
    color: #ffffff;
    content: "S";
    display: inline-block;
    font-family: 'minco-extranet';
    width: 51px;
    height: 32px;
    border-radius: 16.2px;
    font-size: 1.5rem;
    font-weight: normal;
    margin-right: 15px;    
    text-align: center;
    background-color: $minco-dark-blue;
    @include media-breakpoint-down(sm) {
      padding-top: 3px;
    }
  }
}
  .arrowed-link-overlay
  {
    position: absolute;
    bottom: 8.0rem;
    right: 0rem;
  }
  .arrowed-link-overlay:before
  {
    color: #ffffff;
    content: "S";
    display: inline-block;
    font-family: 'minco-extranet';
    width: 3.188rem;
    height: 32px;
    border-radius: 16.2px;
    font-size: 1.5rem;
    font-weight: normal;
    margin-right: 15px;
    text-align: center;
    background-color: $minco-dark-blue;
    @include media-breakpoint-down(sm) {
      padding-top: 3px;
    }
  }

  .arrowed-link-home:after{

  content: "S";
  display: inline-block;
  font-family: 'minco-extranet';
  font-size: 0.938rem;
  vertical-align: middle;
  //border-radius: 9.5px;
  color: #005962;
  margin-left: 0px;
  width: 30px;
  height: 20px;
  text-align: center;
}
  
}
