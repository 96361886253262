.sites-help {

  &__filter {
    label {
      font-family: "Proxima Nova", sans-serif;
      font-weight: bold;
    }
  }

  &__list {
    margin-top: 2em;
  }

  &__item-title {
    font-size: $size-16;
  }

  &__item-desc {
    font-size: $size-15;
    font-family: $font-family-base;
    font-weight: normal;
  }
}
