.table-filters {
  background: $minco-white-three;
  display: flex;
  position: relative;
  flex-wrap: wrap;
}

.table-filter {

}

.filter {
  &__label {
    font-weight: 600;
  }

  &__input {
    .btn {
      display: inherit;
    }

    @include media-breakpoint-up(md) {
      display: flex;
      align-items: center;
      //max-width: 30em;
      width:auto;

      & > * {
        display: flex;
        align-items: center;
        & > * + * {
          margin-left: 1em;
        }
      }

      & > * + * {
        margin-left: 1em;
      }
    }

    &--date {
      //max-width: 30em;
      width:auto;
    }

  }

  &__submit {
    margin-top: 1em;
    @include media-breakpoint-up(md) {
      margin-top: 0;
      margin-left: 2em;
    }
  }
  &__tip {
    cursor: pointer;
    border-radius: 2em;
    border: 2px solid currentColor;
    display: inline-block;
    line-height: 1.6em;
    height: 1.6em;
    width: 1.6em;
    text-align: center;
    margin-left: 0.5em;
  }
}
