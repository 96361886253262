.photolibrary {

  &__left {
    text-align: right;
    color: $minco-white;
    @include media-breakpoint-up(lg) {
      .title-2 {
        font-size: 2.5vw;
      }
    }
  }
  &__right{
    
  }

}
