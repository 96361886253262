.account-services {

  &__items {
    display: table;
    width: 100%;
  }

  &__item {
    padding: 10px 0 6px 0;

    & + & {
      border-top: 1px solid $minco-white-two;
    }
  }

  &__item-label {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    font-weight: bold;
    padding-right: 0.5em;
  }

  &__item-sublabel {
    font-size: 0.8em;
    line-height: 1.3em;
    display: block;
    font-weight: normal;
  }

  &__item-link {
    display: table-cell;
    vertical-align: middle;
    .btn {
      display: inline-flex;
    }
  }

}
