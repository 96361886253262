@import "typography";
$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-xl:                ($font-size-base * 1.25) !important;
$font-size-lg:                ($font-size-base * .875) !important;
$font-size-md:                ($font-size-base * .775) !important;
$font-size-sm:                ($font-size-base * .675) !important;
html {


  @media (min-width: 1200px) { font-size: $font-size-lg ; }
  @media (max-width: 1199.98px) and (min-width: 992px)
  {
    font-size: $font-size-md;
  }
  @media (max-width: 992px) { font-size: $font-size-sm ; }
}
/*♪┏(°.°)┛┗(°.°)┓♪ Global ♪┏(°.°)┛┗(°.°)┓┗♪*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: bold;
}
ol,
ul {
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-padding-start: 0;
  padding: 0;
}
a {
  font-size: $size-15;
  font-weight: 600;
}
textarea {
  resize: none;
}

template {
  display: none;
}

[v-cloak] {
  display: none
}

/*♪┏(°.°)┛┗(°.°)┓♪ Global background classes ♪┏(°.°)┛┗(°.°)┓┗♪*/
.bg-disabled {
  background: -webkit-repeating-linear-gradient(-45deg, $minco-white-three, $minco-white-three 10px, $minco-white-two 11px, $minco-white-two 11px);
  color: rgba($minco-warm-grey, .6);
}
/*♪┏(°.°)┛┗(°.°)┓♪ Global position classes ♪┏(°.°)┛┗(°.°)┓┗♪*/
._absolute-vertical-align {
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
/*♪┏(°.°)┛┗(°.°)┓♪ Global list classes ♪┏(°.°)┛┗(°.°)┓┗♪*/
.list-chevron {
  list-style: none;

  li {
    a {
      display: inline;
      margin-right: 1.1rem;
      @media (max-width: 1280px) {
        margin-right: 0;
      }
    }
    span {
      display: inline;
      vertical-align: middle;
      font-weight: 200;
      @include media-breakpoint-down(sm) {
        font-size: $size-16;
      }
    }
    &:before {
      content: "\53";
      display: inline-block;
      font-family: "minco-extranet";
      font-size: $size-12;
      vertical-align: middle;
      border-radius: 9.5px;
      background-color: #ffffff;
      margin-right: 15px;
      width: 30px;
      height: 19px;
      text-align: center;
      padding-top: 2px;
      @include media-breakpoint-down(sm) {
        font-size: $size-16;
      }
    }
  }
}
.list-inline {
  list-style: none;

  @include media-breakpoint-up(md) {
    li {
      display: inline-block;
    }
    &--dashed {
      li:not(:first-of-type):before {
        color: $minco-white;
        content: '-\00a0';
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
}
/*♪┏(°.°)┛┗(°.°)┓♪ Global title classes ♪┏(°.°)┛┗(°.°)┓┗♪*/
.title-1 {
  font-size: $size-44;
  font-weight: bold;

  @include media-breakpoint-down(md) {
    font-size: $size-28;
  }
}
.title-2 {
  font-size: $size-40;
  font-weight: bold;

  @include media-breakpoint-down(md) {
    font-size: $size-25;
  }
}
.title-3 {
  font-size: $size-28;
  font-weight: bold;

  @include media-breakpoint-down(md) {
    font-size: $size-25;
  }
}
.title-4 {
  font-size: $size-25;
  font-weight: bold;

  @include media-breakpoint-down(md) {
    font-size: $size-18;
  }
}
.title-5 {
  font-size: $size-16;
}
/*♪┏(°.°)┛┗(°.°)┓♪ Global text classes ♪┏(°.°)┛┗(°.°)┓┗♪*/
.small {
  font-size: $size-14;
  &--greyed {
    color: $minco-warm-grey;
  }
}
/*♪┏(°.°)┛┗(°.°)┓♪ Global button classes ♪┏(°.°)┛┗(°.°)┓┗♪*/
.btn {
  &-rounded {
    border-color: transparent;
    border-radius: 8px;
    font-size: $size-15;
    font-weight: 600;
    padding-top: calc(0.4585rem + 1px);
    padding-bottom: calc(0.4585rem + 1px);
    line-height: 1.5;
    &--white {
      background-color: $minco-white;
      color: $minco-black !important;
    }
    &--black {
      background-color: $minco-black;
      color: $minco-white !important;
    }
    &--orange {
      background-color: $minco-pumpkin;
      color: $minco-white !important;
    }
    &--yellow {
      background-color: $minco-yellow;
      color: $minco-white !important;
    }
    &--shadowed {
      box-shadow: 0 2px 4px 0 rgba($minco-black, 0.5);
    }
    &--dark-blue
    {
      background-color: $minco-dark-blue;
      color: $minco-white !important;
    }
  }
}

/*♪┏(°.°)┛┗(°.°)┓♪ Global classes ♪┏(°.°)┛┗(°.°)┓┗♪*/
.icon {
  display: inline-block;
  vertical-align: middle;
  & + span {
    display: inline-block;
    vertical-align: middle;
  }
}
.pointer {
  cursor: pointer;
}
.w-60 {
  width: 60% !important;
}
/*♪┏(°.°)┛┗(°.°)┓♪ Global form classes ♪┏(°.°)┛┗(°.°)┓┗♪*/
.minco {
  &-checkbox,
  &-radio {
    padding-left: 0;
    position: relative;
    &--inline {
      display: inline-block;
    }
    input[type=radio],
    input[type=checkbox] {
      display: none;
      height: 100%;
      margin-top: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      & + label {
        border: 1px solid $minco-pinkish-grey;
        border-radius: 40px;
        cursor: pointer;
        height: calc(2.25rem + 2px);
        text-align: center;
        min-width: 6.25rem;
        padding: 0 1em;

        &:before {
          content: "";
          display: inline-block;
          height: calc(2.25rem + 2px);
          vertical-align: middle;
        }
        span {
          display: inline-block;
          font-size: $size-15;
          line-height: normal;
          position: relative;
          vertical-align: middle;
        }
      }
      &:checked + label {
        background-color: $minco-dark-blue;
        color: $minco-white;
      }
      &:disabled + label {
        opacity: .5;
        &, span {
          cursor: default;
        }
      }
    }
  }
}

/*♪┏(°.°)┛┗(°.°)┓♪ Global form classes ♪┏(°.°)┛┗(°.°)┓┗♪*/
input:invalid {
    box-shadow: none;
}

.has-error {
  input,
  select,
  textarea {
    border-color: $danger;
    color: $danger;
  }
  label {
    color: $danger;
  }
  .form-control-feedback {
    color: $danger;
  }
}

.form-control {
  &:not(textarea) {
    border-radius: 40px;
  }
}

.pt-6
{
  padding-top: 6rem !important;
}

.mt-6
{
  margin-top: 6rem !important;
}

.mt-7
{
  margin-top: 7rem !important;
}

select {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
  padding-right: 2.4em !important;
}
