.cart-delivery-address {
  &__header {
    margin: 0 2.2rem;
  }
  &__title {
    font-size: $size-20;
    margin-bottom: 0;
  }
}


.cart-delivery-methods {
  list-style: none;
}

.cart-delivery-method {
  position: relative;
  background-color: $minco-grass;
  color: white;
  padding: 1em;
  height: 100%;

  &__name {
    display: inline-block;
    font-size: $size-20;
    vertical-align: middle;
    margin-bottom: 1em;
  }

  &__label {
    padding: 0 1em;
  }

  &__info {
    position: absolute;
    bottom: 1.4em;
  }
}
