#app {
  overflow: hidden;
  position: relative;
}

#minco-body {
  min-height: 100vh;
  position: relative;
  padding-top: 58px;

  @include media-breakpoint-up(md) {
    padding-left: calc(2.5em + 15px);
    padding-top: 90px;
  }

  .fullwidth {
    @include media-breakpoint-up(sm) {
      margin-right: -15px;
      margin-left: calc(-2.5em - 15px);
    }
  }

  .fullsize {
    margin-right: -15px;
    margin-left: calc(-2.5em - 15px);
    margin-top: -90px;

    @include media-breakpoint-down(sm) {
      margin-right: -15px;
      margin-left: -15px;
      margin-top: -58px;
    }
  }

}
