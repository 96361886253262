.library {
  &__imaged {
    &__bandeau
    {
      background-image: url('~/build/images/regulation/reglementation-bandeau.jpg');
      background-size: cover;
      height: 27.5rem;
      padding:20px 18px 13px 26px;

      &__texte
      {
        border:solid 1px $minco-white;
        height: 25.3125rem;
        width: 100%;
        background-image: url("~/build/images/regulation/combined-shape-transparent.svg");
        background-position: center;
        background-repeat: no-repeat;
        vertical-align: middle;
        position:relative;
        @media(max-width: 411px) {
          background-image: none;
          border:none;
        }
        h1{
          width:24.1875rem;
          color:$minco-white;
          text-align: center;
          margin-top:8.125rem;
          height:6.25rem;
        }
      }
    }

    .card {
      &-title {
        font-family: "Poppins regular", sans-serif;
        font-size: $size-18;
        text-transform: uppercase;
        color:$minco-black;
      }
    }
    .header {
      position: relative;
      &__text {
        color: $minco-white;
        position: absolute;
        text-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
        top: 60%;
        transform: translateY(-60%);
        width: 40%;

        &__title {
          display: block;
          // font-size: 1.5vw;
          font-size: 3.5vw;

          @include media-breakpoint-up(lg) {
            font-size: 1.5vw;
          }
        }
        &__description {
          display: block;
          font-size: $size-18;
        }
      }
    }
  }

  .waranties
  {
    background-image: url('~/build/images/warranties/fond-garanties.jpg') !important;

  }

  .ecoBandeau
  {
    background-image: url('~/build/images/eco-logic/fond-ecologic.jpg') !important;
  }

  .cercle-orange
  {
    bottom: 9.4375rem ;
    @media (max-width: 411px){
      bottom: 7.8375rem !important;
      padding-top: 5px;
    }
  }
  .form-control
  {
    font-size:0.9375rem !important;
  }

  .h5-mincologic, h5{
    color:$minco-black;

  }
  .h5-mincologic:before{
    color: #ffffff;
    content: "S";
    display: inline-block;
    font-family: 'minco-extranet';
    font-size: 0.938rem;
    vertical-align: middle;
    border-radius: 9.5px;
    background-color: #005962;
    margin-right: 15px;
    maring-left: -45px;
    width: 30px;
    height: 20px;
    text-align: center;
    padding-top:4px;
  }
}
