#minco-body {
  .new-project {
    &__content {
      position: relative;

      &__header {
        background-color: $minco-white-three;
        h2 {
          font-family: "Poppins regular", sans-serif;
          font-size: $size-20;
          text-transform: uppercase;
        }
        .btn-rounded {
          text-transform: none;
          width: 2.5rem;
          @include media-breakpoint-up(md) {
            width: 5.5rem;
          }

          span {
            display: none;
            @include media-breakpoint-up(md) {
              display: block;
            }
          }

          i {
            display: block;
            @include media-breakpoint-up(md) {
              display: none;
            }
          }
        }
      }
      &__body {
        .iframe{
          .modal-dialog.modal-xl.modal-dialog-centered
          {
            height:90%;
            max-width: 90%;
            .modal-content
            {
              height:90%;
              .modal-header {
                padding-bottom: 0 !important;
              }

              .modal-body
              {
                height:85%;
                padding-top: 0 !important;
                > div{
                  height:90%;
                  position:relative;
                  iframe{
                    display: inline-block;
                    border: none;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    height: 100%;
                  }
                }
              }
            }
          }

        }
        label,
        h4 {
          font-family: "Poppins regular", sans-serif;
        }
        h4 {
          font-size: $size-20;
        }
        h5 {
          font-size: $size-16;
        }
        .input-group {
          &--small {
            @include media-breakpoint-up(lg) {
              width: 4.89vw;
            }

            .form-control {
              padding: .375rem .1rem;
            }
          }
        }
        .quote {
          &__edit {
            &__icons {
              width: 2.25rem;
            }
            &__select {
              display: inline-block;
              width: 80%;
            }
            &__button {
              background-color: transparent;
              border-color: transparent;
              cursor: pointer;
              font-family: "Poppins regular", sans-serif;
              position: relative;
              margin-left: 1.7rem;
              text-align: left;
              &:before {
                bottom: 0;
                color: $minco-grass;
                content: "S";
                font-family: "minco-extranet";
                font-size: 3.5rem;
                left: -1.7rem;
                position: absolute;
                top: -1rem;
                z-index: -1;
              }
            }
          }
          &__order {
            label,
            .title-5 {
              font-family: "Poppins regular Alt", sans-serif;
            }
            &__boxes {
              .icon {
                font-size: 3.45rem;
                @include media-breakpoint-between(md, lg) {
                  font-size: 2.45rem;
                }
              }
            }
          }
        }
        button.quote__edit__select {
          cursor: pointer;
        }
        .bg-white-three {
          font-size: $size-14;
          .input-group {
            .form-control {
              padding: .375rem .4rem;
            }
          }
        }
      }
    }
  }
}
