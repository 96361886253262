.delays {
  .icon-calendar {
    font-size: 4em;
  }

    &__left {
      text-align: right;
      @include media-breakpoint-up(lg) {
        .title-2 {
          font-size: 1.9vw;
        }
      }
    }
    &__right{
      .bg-grass {
        color: $minco-white;
        @include media-breakpoint-up(lg) {
          font-size: 1vw;
          .title-2 {
            font-size: 2vw;
          }
          .title-3 {
            font-size: 1.9vw;
          }
        }
        .icon {
          font-size: 3.45rem;
          @include media-breakpoint-between(md, lg) {
            font-size: 2.45rem;
          }
        }
        .list-unstyled.text-right {
          font-family: "Proxima Nova", sans-serif;
          font-weight: 600;
        }
      }
    }
}
