.switch {
  text-align: center;
  position: relative;
  display: inline-block;
  vertical-align: top;

  font-size: 0.938rem;
  height: 2.8em;
  line-height: 2.8em;

  width: 100px;

  background-color: $minco-white;
  border-radius: 2.8em;
  box-shadow: 0 2px 4px 0 rgba($minco-black, 0.5);
  cursor: pointer;
}

.switch-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.switch-label {
  position: relative;
  display: block;
  height: inherit;
  font-size: 1em;
  text-transform: uppercase;
  border-radius: inherit;
}

.switch-label:before, .switch-label:after {
  position: absolute;
  top: 50%;
  line-height: 1;
  transform: translateY(-50%);
  transition: inherit;
  width: 50%;
}

.switch-label:before {
  content: attr(data-on);
  left: 0;
  //background-color: darkgreen;
  //border-radius: 1.8em 0 0 1.8em;
  //padding-top: 15%;
  //height:42.02px;
  //display: inline-block;
}

.switch-label:after {
  content: attr(data-off);
  right: 0;
  //border-radius: 0 1.8em 1.8em 0;
  //background-color: darkred;
  //padding-top: 15%;
  //height:42.02px;
  //display: inline-block;
}


.switch-handle {
  position: absolute;
  left: 50%;
  top: 0;
  height: 2.8em;
  line-height: 2.8em;
  width: 50%;
  color: $minco-white;
  background-color: $warning;

  border-radius: 2.8em;
  text-transform: uppercase;
  transition: left 0.15s ease-out;

  &:after {
    content: attr(data-off);
    display: inline;
  }
}


.switch-input:checked ~ .switch-handle {
  left: 0;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);

  background-color: $success;

  &:after {
    content: attr(data-on);
  }
}
