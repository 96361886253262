.project-terms {
  h2, h3 {
    font-family: "Proxima Nova", sans-serif;
  }

  &__text {
    h3 {
      margin-bottom: 0.5rem !important;
    }
  }

}
